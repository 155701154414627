import React, { useEffect } from 'react';
import { PageWrapper } from '../../Styles/style';
import HeroSection from '../../Sections/S1_Hero';
import AboutSection from '../../Sections/S2_About';
import Tokenomics from '../../Sections/S5_GameReward';
import DisclaimerSection from '../../Sections/S8_Footer';
import HowToBuy from '../../Sections/S3_ChristmazGame';
import Team from '../../Sections/S6_Tokenomics';
import Tokonew from '../../Sections/S4_HowItWorks';
import emotionStyled from '@emotion/styled';
import News from '../../Sections/S7_HowToBuy';
import Aos from "aos";
import "aos/dist/aos.css";

// Full-screen snowflake container
const SnowflakeContainer = emotionStyled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  pointer-events: none;
  z-index: 9999;

  .snowflake {
    position: absolute;
    top: -50px;
    color: #FFF;
    opacity: 0.8;
    pointer-events: none;
  }
`;

// Main Component
const MainPage = (props) => {
  useEffect(() => {
    Aos.init({ duration: 2000 });
    window.addEventListener("load", function () {
      Aos.init();
    });

    // Snowflake animation
    const container = document.querySelector('.snowflakes-container');

    function createSnowflake() {
      const snowflake = document.createElement('div');
      snowflake.classList.add('snowflake');
      snowflake.textContent = '•';
      snowflake.style.fontSize = Math.random() * 24 + 10 + 'px';
      snowflake.style.left = Math.random() * window.innerWidth + 'px';
      snowflake.style.animation = `
        fall ${Math.random() * 4 + 4}s linear infinite, 
        sideWays ${Math.random() * 2 + 1}s ease-in-out infinite`;

      container.appendChild(snowflake);

      setTimeout(() => {
        snowflake.remove();
      }, Math.random() * 4000 + 4000);
    }

    // Add keyframes dynamically
    const styleSheet = document.styleSheets[0];
    styleSheet.insertRule(
      '@keyframes fall { 0% { top: -50px; } 100% { top: 100vh; } }',
      styleSheet.cssRules.length
    );
    styleSheet.insertRule(
      '@keyframes sideWays { 0%, 100% { transform: translateX(0); } 50% { transform: translateX(20px); } }',
      styleSheet.cssRules.length
    );

    // Start creating snowflakes
    const interval = setInterval(createSnowflake, 100);

    // Cleanup on unmount
    return () => clearInterval(interval);
  }, []);

  return (
    <>
      {/* Full-screen snowflake container */}
      <SnowflakeContainer className="snowflakes-container" />
      
      <PageWrapper>
        <HeroSection />
        <AboutSection />
        <HowToBuy />
        <Tokonew />
        <Tokenomics />
        <Team />
        <News />
        <DisclaimerSection />
      </PageWrapper>
    </>
  );
};

export default MainPage;
