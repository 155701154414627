import React from 'react';
import { Typography, Box, Container, Grid } from '@mui/material';
import gamerewardbg from "../../../assets/gamerewardbg.png"
import AOS from 'aos';
import 'aos/dist/aos.css'; // Import the AOS styles
import emotionStyled from '@emotion/styled';
import styled from '@emotion/styled';

// Initialize AOS
AOS.init({
  duration: 1200, // Duration of animations (optional)
  once: true, // Whether animation should happen only once - while scrolling down (optional)
  mirror: false, // Whether elements should animate out while scrolling past them (optional)
});
const SectionWraper = emotionStyled(Box)`
 background-image:url(${gamerewardbg});
    background-size:cover;
    background-position: center center;
    background-repeat:no-repeat;
    min-height:100vh;
    padding:80px 0;
    background-repeat:no-repeat;
    // background-color:#39933C;
    // min-height:100VH;
    width:100%;
    justify-content:center;
    align-items:center;
`

const Wrapper = styled(Box)`
background: linear-gradient(0deg, #D03428, #FF3B2B);
color:#fff;
justify-content:left;
padding:10px 30PX 30px;
box-shadow: 2px 6px 0px 0px #A71D12,inset 2px 0 0 0 #F09B94;
border:2px solid #FF4738;
`
const SubWrapper = styled(Box)`
background: #FF4738;
border:1px solid #A71D12;
color:#fff;
justify-content:left;
padding:20px 20PX;
height:70px;
margin:0px 0PX;
display: flex;
align-items:center;
@media screen and (max-width:599px){
  padding:7px;

}
`
const SubWrapperX = styled(Box)`
background: #FF3120;
border:1px solid #A71D12;
color:#fff;
justify-content:left;
padding:20px 20PX;
height:70px;
margin:0px 0PX;
display: flex;
align-items:center;
@media screen and (max-width:599px){
  padding:7px;

}
`
const WrapperS = styled(Box)`
background: linear-gradient(0deg, #36982E, #6DBE45);
color:#fff;
justify-content:left;
padding:10px 30PX 30px;
box-shadow: 2px 6px 0px 0px #034928,inset 2px 0 0 0 #AFDA9F;
border:2px solid #034928;

`

const SubWrapperS = styled(Box)`
background: #3A9B30;
border:1px solid #034928;
color:#fff;
justify-content:left;
padding:20px 20PX;
height:70px;
margin:0px 0PX;
display: flex;
align-items:center;
@media screen and (max-width:599px){
  padding:7px;

}
`
const SubWrapperSx = styled(Box)`
background: #129803;
border:1px solid #034928;
color:#fff;
justify-content:left;
padding:20px 20PX;
height:70px;
margin:0px 0PX;
display: flex;
align-items:center;
@media screen and (max-width:599px){
  padding:7px;

}
`

const PhasesSection = () => {
  return (

    <SectionWraper id="Roadmap">
      <Container maxWidth={"xl"}>
        <Typography style={{ textShadow: "0px 0px 11px black", }} data-aos="fade-up" data-aos-duration="900" data-aos-easing="ease-in-out" data-aos-delay="700" data-aos-offset="0" padding="20px 0 120px" variant='h3' fontFamily="PSM" textAlign='left' color="#fff" textTransform="camelcase"
          fontSize={{ xs: "57px", sm: "100px" }} >
          🎮Game <br />
          Rewards🎁</Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Wrapper data-aos="fade-right" data-aos-duration="1000" data-aos-easing="ease-in-out" data-aos-delay="600" data-aos-offset="0" >
              <Typography padding="20px 0 20px" variant='h3' fontFamily="PSM" textAlign='left' color="#fff" textTransform="camelcase"
                fontSize={{ xs: "22px", sm: "25px" }} >
                 Daily Leaderboard</Typography>
              <Typography textAlign='left' color="#fff" textTransform="camelcase" variant='h3'
                fontFamily="pr" padding="0px 0 20px"
                fontSize={{ xs: "14px", sm: "18px" }} >
                Every day, the top 3 players with the highest scores will win lovely
                Christmas presents.
                <br />
                <br />
                $40 worth of iTunes,$20 worth of iTunes and $10 worth of iTunes.
              </Typography>
              <Grid container >
                <Grid item xs={2}>
                  <Typography textAlign='left' color="#fff" textTransform="camelcase" variant='h3'
                    fontFamily="pm"
                    padding={{ xs: "5px 0", md: "10px 0" }}

                    fontSize={{ xs: "12px", md: "13px", lg: "18px" }} >
                    Rank
                  </Typography>

                  <SubWrapper>
                    <Typography
                      textAlign='left'
                      color="#fff"
                      textTransform="camelcase"
                      variant='h3'
                      fontFamily="psm"
                      padding="0px 0 0px"
                      fontSize={{ xs: "12px", md: "13px", lg: "22px" }}
                      style={{ position: 'relative', display: 'inline-block' }} // Added relative positioning
                    >
                      <span style={{ 
                      fontFamily:"psm", position: 'absolute', top: '0px', right: '-13px', fontSize: '12px' }}>st</span>
                      1
                    </Typography>

                  </SubWrapper>

                </Grid>
                <Grid item xs={5}>
                  <Typography textAlign='left' color="#fff" textTransform="camelcase" variant='h3'
                    fontFamily="pm" padding={{ xs: "5px 0", md: "10px 0" }}
                    fontSize={{ xs: "12px", md: "13px", lg: "18px" }} >
                    Prize
                  </Typography>

                  <SubWrapperX>
                    <Typography textAlign='left' color="#fff" textTransform="camelcase" variant='h3'
                      fontFamily="pm" padding="0px 0 0px"
                      fontSize={{ xs: "12px", md: "13px", lg: "18px" }} >

                      $40 worth of iTunes
                    </Typography>
                  </SubWrapperX>

                </Grid>
                <Grid item xs={5}>
                  <Typography textAlign='left' color="#fff" textTransform="camelcase" variant='h3'
                    fontFamily="pm" padding={{ xs: "5px 0", md: "10px 0" }}
                    fontSize={{ xs: "12px", md: "13px", lg: "18px" }} >
                    Winner
                  </Typography>

                  <SubWrapper>
                    <Typography textAlign='left' color="#fff" textTransform="camelcase" variant='h3'
                      fontFamily="pm" padding="0px 0 0px"
                      fontSize={{ xs: "12px", md: "13px", lg: "18px" }} >

                      @username
                    </Typography>
                  </SubWrapper>

                </Grid>
              </Grid>
              <Grid container >
                <Grid item xs={2}>

                  <SubWrapper>
                    <Typography
                      textAlign='left'
                      color="#fff"
                      textTransform="camelcase"
                      variant='h3'
                      fontFamily="psm"
                      padding="0px 0 0px"
                      fontSize={{ xs: "12px", md: "13px", lg: "22px" }}
                      style={{ position: 'relative', display: 'inline-block' }} // Added relative positioning
                    >
                      <span style={{ fontFamily:"psm",position: 'absolute', top: '0px', right: '-17px', fontSize: '12px' }}>nd</span>
                      2
                    </Typography>
                  </SubWrapper>

                </Grid>
                <Grid item xs={5}>

                  <SubWrapperX>
                    <Typography textAlign='left' color="#fff" textTransform="camelcase" variant='h3'
                      fontFamily="pm" padding="0px 0 0px"
                      fontSize={{ xs: "12px", md: "13px", lg: "18px" }} >

                      $20 worth of iTunes                    </Typography>
                  </SubWrapperX>

                </Grid>
                <Grid item xs={5}>

                  <SubWrapper>
                    <Typography textAlign='left' color="#fff" textTransform="camelcase" variant='h3'
                      fontFamily="pm" padding="0px 0 0px"
                      fontSize={{ xs: "12px", md: "13px", lg: "18px" }} >

                      @username
                    </Typography>
                  </SubWrapper>

                </Grid>
              </Grid>
              <Grid container >
                <Grid item xs={2}>

                  <SubWrapper>
                    <Typography
                      textAlign='left'
                      color="#fff"
                      textTransform="camelcase"
                      variant='h3'
                      fontFamily="psm"
                      padding="0px 0 0px"
                      fontSize={{ xs: "12px", md: "13px", lg: "22px" }}
                      style={{ position: 'relative', display: 'inline-block' }} // Added relative positioning
                    >
                      <span style={{ fontFamily:"psm",position: 'absolute', top: '0px', right: '-13px', fontSize: '12px' }}>rd</span>
                      3
                    </Typography>
                  </SubWrapper>

                </Grid>
                <Grid item xs={5}>

                  <SubWrapperX>
                    <Typography textAlign='left' color="#fff" textTransform="camelcase" variant='h3'
                      fontFamily="pm" padding="0px 0 0px"
                      fontSize={{ xs: "12px", md: "13px", lg: "18px" }} >

                      $10 worth of iTunes                                     </Typography>
                  </SubWrapperX>

                </Grid>
                <Grid item xs={5}>

                  <SubWrapper>
                    <Typography textAlign='left' color="#fff" textTransform="camelcase" variant='h3'
                      fontFamily="pm" padding="0px 0 0px"
                      fontSize={{ xs: "12px", md: "13px", lg: "18px" }} >

                      @username
                    </Typography>
                  </SubWrapper>

                </Grid>
              </Grid>

            </Wrapper>
          </Grid>
          <Grid item xs={12} md={6}>
            <WrapperS data-aos="fade-left" data-aos-duration="1000" data-aos-easing="ease-in-out" data-aos-delay="600" data-aos-offset="0" >
              <Typography padding="20px 0 20px" variant='h3' fontFamily="PSM" textAlign='left' color="#fff" textTransform="camelcase"
                fontSize={{ xs: "18px", sm: "25px" }} >
                 Weekly Leaderboard</Typography>
              <Typography textAlign='left' color="#fff" textTransform="camelcase" variant='h3'
                fontFamily="pr" padding="0px 0 20px"
                fontSize={{ xs: "14px", sm: "18px" }} >
                Every Sunday, we’ll reveal the major weekly standings. The top 5 players of the week will win massive Christmas presents!
                <br />
                <br />
                Playstation 5,Airpods,Black ops 6 ,4th $50 christmas sweater and 5th $30 worth of iTunes.
              </Typography>

              <Grid container >
                <Grid item xs={2}>
                  <Typography textAlign='left' color="#fff" textTransform="camelcase" variant='h3'
                    fontFamily="pm" padding={{ xs: "5px 0", md: "10px 0" }}
                    fontSize={{ xs: "12px", md: "13px", lg: "18px" }} >
                    Rank
                  </Typography>

                  <SubWrapperS>
                    <Typography
                      textAlign='left'
                      color="#fff"
                      textTransform="camelcase"
                      variant='h3'
                      fontFamily="psm"
                      padding="0px 0 0px"
                      fontSize={{ xs: "12px", md: "13px", lg: "22px" }}
                      style={{ position: 'relative', display: 'inline-block' }} // Added relative positioning
                    >
                      <span style={{fontFamily:"psm", position: 'absolute', top: '0px', right: '-13px', fontSize: '12px' }}>st</span>
                      1
                    </Typography>
                  </SubWrapperS>

                </Grid>
                <Grid item xs={5}>
                  <Typography textAlign='left' color="#fff" textTransform="camelcase" variant='h3'
                    fontFamily="pm" padding={{ xs: "5px 0", md: "10px 0" }}
                    fontSize={{ xs: "12px", md: "13px", lg: "18px" }} >
                    Prize
                  </Typography>

                  <SubWrapperSx>
                    <Typography textAlign='left' color="#fff" textTransform="camelcase" variant='h3'
                      fontFamily="pm" padding="0px 0 0px"
                      fontSize={{ xs: "12px", md: "13px", lg: "18px" }} >

                      PlayStation 5                    </Typography>
                  </SubWrapperSx>

                </Grid>
                <Grid item xs={5}>
                  <Typography textAlign='left' color="#fff" textTransform="camelcase" variant='h3'
                    fontFamily="pm" padding={{ xs: "5px 0", md: "10px 0" }}
                    fontSize={{ xs: "12px", md: "13px", lg: "18px" }} >
                    Winner
                  </Typography>

                  <SubWrapperS>
                    <Typography textAlign='left' color="#fff" textTransform="camelcase" variant='h3'
                      fontFamily="pm" padding="0px 0 0px"
                      fontSize={{ xs: "12px", md: "13px", lg: "18px" }} >

                      @username
                    </Typography>
                  </SubWrapperS>

                </Grid>
              </Grid>
              <Grid container >
                <Grid item xs={2}>

                  <SubWrapperS>
                    <Typography
                      textAlign='left'
                      color="#fff"
                      textTransform="camelcase"
                      variant='h3'
                      fontFamily="psm"
                      padding="0px 0 0px"
                      fontSize={{ xs: "12px", md: "13px", lg: "22px" }}
                      style={{ position: 'relative', display: 'inline-block' }} // Added relative positioning
                    >
                      <span style={{ fontFamily:"psm",position: 'absolute', top: '0px', right: '-17px', fontSize: '12px' }}> nd</span>
                      2
                    </Typography>
                  </SubWrapperS>

                </Grid>
                <Grid item xs={5}>

                  <SubWrapperSx>
                    <Typography textAlign='left' color="#fff" textTransform="camelcase" variant='h3'
                      fontFamily="pm" padding="0px 0 0px"
                      fontSize={{ xs: "12px", md: "13px", lg: "18px" }} >

                      Airpods                                        </Typography>
                  </SubWrapperSx>

                </Grid>
                <Grid item xs={5}>

                  <SubWrapperS>
                    <Typography textAlign='left' color="#fff" textTransform="camelcase" variant='h3'
                      fontFamily="pm" padding="0px 0 0px"
                      fontSize={{ xs: "12px", md: "13px", lg: "18px" }} >

                      @username
                    </Typography>
                  </SubWrapperS>

                </Grid>
              </Grid>
              <Grid container >
                <Grid item xs={2}>

                  <SubWrapperS>
                    <Typography
                      textAlign='left'
                      color="#fff"
                      textTransform="camelcase"
                      variant='h3'
                      fontFamily="psm"
                      padding="0px 0 0px"
                      fontSize={{ xs: "12px", md: "13px", lg: "22px" }}
                      style={{ position: 'relative', display: 'inline-block' }} // Added relative positioning
                    >
                      <span style={{fontFamily:"psm", position: 'absolute', top: '0px', right: '-13px', fontSize: '12px' }}>rd</span>
                      3
                    </Typography>
                  </SubWrapperS>

                </Grid>
                <Grid item xs={5}>

                  <SubWrapperSx>
                    <Typography textAlign='left' color="#fff" textTransform="camelcase" variant='h3'
                      fontFamily="pm" padding="0px 0 0px"
                      fontSize={{ xs: "12px", md: "13px", lg: "18px" }} >

                      Black ops 6                                   </Typography>
                  </SubWrapperSx>

                </Grid>
                <Grid item xs={5}>

                  <SubWrapperS>
                    <Typography textAlign='left' color="#fff" textTransform="camelcase" variant='h3'
                      fontFamily="pm" padding="0px 0 0px"
                      fontSize={{ xs: "12px", md: "13px", lg: "18px" }} >

                      @username
                    </Typography>
                  </SubWrapperS>

                </Grid>
              </Grid>
              <Grid container >
                <Grid item xs={2}>

                  <SubWrapperS>
                    <Typography
                      textAlign='left'
                      color="#fff"
                      textTransform="camelcase"
                      variant='h3'
                      fontFamily="psm"
                      padding="0px 0 0px"
                      fontSize={{ xs: "12px", md: "13px", lg: "22px" }}
                      style={{ position: 'relative', display: 'inline-block' }} // Added relative positioning
                    >
                      <span style={{ fontFamily:"psm",position: 'absolute', top: '0px', right: '-13px', fontSize: '12px' }}>th</span>
                      4
                    </Typography>
                  </SubWrapperS>

                </Grid>
                <Grid item xs={5}>

                  <SubWrapperSx>
                    <Typography textAlign='left' color="#fff" textTransform="camelcase" variant='h3'
                      fontFamily="pm" padding="0px 0 0px"
                      fontSize={{ xs: "12px", md: "13px", lg: "18px" }} >

                      $50 Christmas Sweater                                    </Typography>
                  </SubWrapperSx>

                </Grid>
                <Grid item xs={5}>

                  <SubWrapperS>
                    <Typography textAlign='left' color="#fff" textTransform="camelcase" variant='h3'
                      fontFamily="pm" padding="0px 0 0px"
                      fontSize={{ xs: "12px", md: "13px", lg: "18px" }} >

                      @username
                    </Typography>
                  </SubWrapperS>

                </Grid>
              </Grid>
              <Grid container >
                <Grid item xs={2}>

                  <SubWrapperS>
                    <Typography
                      textAlign='left'
                      color="#fff"
                      textTransform="camelcase"
                      variant='h3'
                      fontFamily="psm"
                      padding="0px 0 0px"
                      fontSize={{ xs: "12px", md: "13px", lg: "22px" }}
                      style={{ position: 'relative', display: 'inline-block' }} // Added relative positioning
                    >
                      <span style={{fontFamily:"psm", position: 'absolute', top: '0px', right: '-13px', fontSize: '12px' }}>th</span>
                      5
                    </Typography>
                  </SubWrapperS>

                </Grid>
                <Grid item xs={5}>

                  <SubWrapperSx>
                    <Typography textAlign='left' color="#fff" textTransform="camelcase" variant='h3'
                      fontFamily="pm" padding="0px 0 0px"
                      fontSize={{ xs: "12px", md: "13px", lg: "18px" }} >

                      $30 Worth of iTunes                                 </Typography>
                  </SubWrapperSx>

                </Grid>
                <Grid item xs={5}>

                  <SubWrapperS>
                    <Typography textAlign='left' color="#fff" textTransform="camelcase" variant='h3'
                      fontFamily="pm" padding="0px 0 0px"
                      fontSize={{ xs: "12px", md: "13px", lg: "18px" }} >

                      @username
                    </Typography>
                  </SubWrapperS>

                </Grid>
              </Grid>

            </WrapperS>
          </Grid>
        </Grid>

      </Container>
    </SectionWraper>
    // <Carousel />
  );
};

export default PhasesSection;
