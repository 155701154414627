import styled from "@emotion/styled";
import { Box, Container, Grid, Typography } from "@mui/material";
import React from "react";
import emotionStyled from "@emotion/styled";
import ecostake from "../../../assets/xmaxgift.png"

import AOS from 'aos';
import 'aos/dist/aos.css'; // Import the AOS styles
import Paragraph from "../../Components/Paragraph";
// Initialize AOS
AOS.init({
    duration: 1200, // Duration of animations (optional)
    once: true, // Whether animation should happen only once - while scrolling down (optional)
    mirror: false, // Whether elements should animate out while scrolling past them (optional)
});
const SectionWrapper = emotionStyled(Box)`
    
    padding: 80px 0 50PX;
    
`
const CustomGrid = emotionStyled(Grid)`
//   display: flex;
  width: 100%;
  align-items: ${(props) => (props.ai ? props.ai : "center")};
  justify-content: ${(props) => (props.jc ? props.jc : "center")};
  text-align: ${(props) => (props.tl ? props.tl : "center")};
  padding: ${(props) => (props.p ? props.p : "0px")};
  margin: ${(props) => (props.m ? props.m : "0")};
  gap: ${(props) => (props.g ? props.g : "0")};
  
`;
const GridSWrap = emotionStyled(Grid)`
  padding:30px 0;
  `
const EcoImg = emotionStyled.img`
      width:100%;
      height:auto;
    `;


const HowToBuy = () => {
    return (
        <SectionWrapper >
            <Container maxWidth="xl" id="Merchandise">
                <Container maxWidth="xl" >
                    <CustomGrid data-aos="zoom-in"
                        data-aos-duration="1000" data-aos-easing="ease-in-out" data-aos-delay="500" data-aos-offset="0"
                        item xs={12}>
                               {/* <Typography data-aos="fade-up" data-aos-duration="900" data-aos-easing="ease-in-out" data-aos-delay="100" data-aos-offset="0" padding="20px 0 0px" variant='h3' fontFamily="PM" textAlign='center' color="#fff" textTransform="camelcase" fontSize={{ xs: "16px", sm: "20px" }} >
                            <span style={{ color: "#FCED3D", fontFamily: "PM" }}>🎄 Exciting Announcement 🎄</span></Typography> */}
                        <Typography style={{ textShadow: "0px 0px 11px black", }} data-aos="fade-up" data-aos-duration="900" data-aos-easing="ease-in-out" data-aos-delay="100" data-aos-offset="0" padding="20px 0 20px" variant='h3' fontFamily="PSM" textAlign='center' color="#fff" textTransform="camelcase" fontSize={{ xs: "35px", sm: "70px" }} >
                        Customized Christmas Game</Typography>
                        </CustomGrid>
                        
                        <Paragraph data-aos="fade-zoom-in"
     data-aos-easing="ease-in-back"
     data-aos-delay="500"
     data-aos-offset="0" padding="0px 0 20px" textAlign="center">Get ready to play, earn, and celebrate Christmas like never before! We're launching a special game to <br/>
                        bring holiday cheer and rewards to all our players.
                        </Paragraph>

                        
                    <GridSWrap container sx={{ justifyContent: "CENTER", display: "flex", gap: { xs: "0px", lg: "20px" } }} >
                        <Grid xs={12} md={11} sx={{ justifyContent: "CENTER", }} data-aos="fade-zoom-in"
     data-aos-easing="ease-in-back"
     data-aos-delay="500"
     data-aos-offset="0"  >
                        <EcoImg draggable="false" alt="image" src={ecostake} />
                         
                        </Grid>
                    </GridSWrap>
                    
                </Container>
            </Container>
        </SectionWrapper>
    );
};

export default HowToBuy;