import emotionStyled from '@emotion/styled'
import React, { useState } from 'react'
import { Box, Container, Grid, Typography } from '@mui/material'
import Paragraph from '../../Components/Paragraph'
import styled from '@emotion/styled'
import GamePad_mockup from "../../../assets/GamePad_mockup.png"
import AOS from 'aos';
import 'aos/dist/aos.css'; // Import the AOS styles
// Initialize AOS
AOS.init({
    duration: 1200, // Duration of animations (optional)
    once: true, // Whether animation should happen only once - while scrolling down (optional)
    mirror: false, // Whether elements should animate out while scrolling past them (optional)
});
const SectionWraper = emotionStyled(Box)`
    display:flex;
    padding:0px 0 130px;
    justify-content:center;
    align-items:center;
    position:relative;
`
const Icons = styled.img`
width: 100%;
height:auto;
position:absolute;
max-width:350px;
top:25px;


left: 50%;
right: 50%;
place-self: CENTER;

@media (max-width: 599px) {
        max-width:300px;


    }
`
const ContainerBox = styled.div`
  padding: 30px 0 12px;
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;

  @media (max-width: 599px) {
    justify-content: center; /* Center the items for mobile */
    
    a {
      width: calc(50% - 5px); /* Each item takes up half of the row */
    }
  }
`;

const Wrapper = styled(Box)`
background: linear-gradient(0deg, #36982E, #6DBE45) ;
border:2px solid #034928;
color:#fff;
width:100%;
height:80px;
display:flex;
align-items:center;
justify-content:left;
padding:10px 20PX 10px;
box-shadow: 2px 6px 0px 0px #034928 , inset 2px 0px 0px 0px #A6D49C;
`
const WrapperS = styled(Box)`
box-shadow: 2px 6px 0px 0px #034928 , inset 2px 0px 0px 0px #A6D49C;
background: linear-gradient(0deg, #36982E, #6DBE45);
border:2px solid #034928;
justify-content:center;
color:#fff;
width:6%;
height:80px;
display:flex;
align-items:center;
text-align: center;
padding:10px 20PX 10px;

`

const AboutSection = () => {


    return (
        <SectionWraper >
            <Container maxWidth="xl" >
                <Typography style={{ textShadow: "0px 0px 11px black", }} data-aos="fade-up" data-aos-duration="700" data-aos-easing="ease-in-out" data-aos-delay="500" data-aos-offset="0" padding="20px 0 120px" variant='h3' fontFamily="PSM" textAlign='center' color="#fff" textTransform="camelcase" 
                fontSize={{ xs: "52px", sm: "60px" }} >
                    How It Works</Typography>
                <Icons draggable="false" data-aos="fade-up" data-aos-duration="900" data-aos-easing="ease-in-out" data-aos-delay="700" data-aos-offset="0" alt="image" src={GamePad_mockup} />
                <Grid container sx={{ display: "flex", justifyContent: "center", alignItems: { xs: "center", md: "center" } }}>

                    <Grid item xs={12} md={10} >
                        <ContainerBox>
                            <WrapperS data-aos="fade-up" data-aos-duration="700" data-aos-easing="ease-in-out" data-aos-delay="700" data-aos-offset="0">
                                <Paragraph   textAlign="center" color="white" fontFamily="psm"  fontSize={{ xs: "25px", sm: "40px" }} >
                                    1
                                </Paragraph>

                            </WrapperS>
                            <Wrapper data-aos="fade-up" data-aos-duration="700" data-aos-easing="ease-in-out" data-aos-delay="700" data-aos-offset="0">
                                <Paragraph   textAlign="left" color="white" fontFamily="psm"  fontSize={{ xs: "16px", sm: "20px" }} >
                                    Play the game and collect points.
                                </Paragraph>
                            </Wrapper>
                        </ContainerBox>
                        <ContainerBox>
                            <WrapperS data-aos="fade-up" data-aos-duration="800" data-aos-easing="ease-in-out" data-aos-delay="800" data-aos-offset="0">
                                <Paragraph  textAlign="center" color="white" fontFamily="psm"  fontSize={{ xs: "25px", sm: "40px" }} >
                                    2
                                </Paragraph>

                            </WrapperS>
                            <Wrapper data-aos="fade-up" data-aos-duration="800" data-aos-easing="ease-in-out" data-aos-delay="800" data-aos-offset="0">
                                <Paragraph  textAlign="left" color="white" fontFamily="psm"  fontSize={{ xs: "16px", sm: "20px" }} >
                                    Compete with others to climb the leaderboard.                                 </Paragraph>
                            </Wrapper>
                        </ContainerBox>
                        <ContainerBox>
                            <WrapperS data-aos="fade-up" data-aos-duration="900" data-aos-easing="ease-in-out" data-aos-delay="1000" data-aos-offset="0">
                                <Paragraph  textAlign="center" color="white" fontFamily="psm"  fontSize={{ xs: "25px", sm: "40px" }} >
                                    3
                                </Paragraph>

                            </WrapperS>
                            <Wrapper data-aos="fade-up" data-aos-duration="900" data-aos-easing="ease-in-out" data-aos-delay="1000" data-aos-offset="0">
                                <Paragraph  textAlign="left" color="white" fontFamily="psm"  fontSize={{ xs: "16px", sm: "20px" }} >
                                    Top players will receive amazing Christmas presents!                                </Paragraph>
                            </Wrapper>
                        </ContainerBox>




                    </Grid>
                </Grid>
            </Container>
        </SectionWraper >
    )
}

export default AboutSection