import styled from "@emotion/styled";

const PageWrapper = styled.div`
    background-color: #0A3E7E;
    min-height: 100vh;
    position: relative;
    overflow: hidden;
`

const Image = styled.img`
width: 100%;
max-width: ${props=>props.mw? props.mw : "100%"};
height: auto;
display: ${props=>props.disp && props.disp };
@media(max-width: 599px){
    
display: ${props=>props.disp599 && props.disp599 };
}

transition: all 0.2s ease;
box-shadow: -4px 6px 0px 2px black;
:active{
        
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
        transform: translateY(2px);
    
    }
`

export { PageWrapper, Image}