// CarouselComponent.js
import React, { useEffect, useState } from 'react';
import footerimg from "../../../assets/footerimg.png";
import { Box, Container, Grid, Typography } from '@mui/material';
import styled from '@emotion/styled';
import emotionStyled from "@emotion/styled";
import Paragraph from '../../Components/Paragraph';

import FileCopyIcon from '@mui/icons-material/FileCopy';
import { contractAddress } from '../../../links';
const SectionWrapper = emotionStyled(Box)`
  
    padding: 80px 0;
`
const EcoImg = styled.img`
width:100%;
height:auto;
`;
// Carousel Component
const CarouselComponent = () => {

    const [isCopied, setIsCopied] = useState(false)
    const handleCopy = async () => {
        setIsCopied(true)
        await navigator.clipboard.writeText(contractAddress)
        setTimeout(() => {
            setIsCopied(false)
        }, 3000);
    }
    return (
        <SectionWrapper id="HowtoBuy">

            <Container maxWidth={'xl'} >
                <Grid container spacing={4} alignItems={"CENTER"}>

                    <Grid item xs={12} md={6} data-aos="fade-right" data-aos-duration="900" data-aos-easing="ease-in-out" data-aos-delay="500" data-aos-offset="0">
                        <EcoImg draggable="false" src={footerimg} />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <Typography data-aos="fade-up" data-aos-duration="900" data-aos-easing="ease-in-out" data-aos-delay="100" data-aos-offset="0" padding="20px 0 0px" variant='h3' fontFamily="PM" textAlign='left' color="#fff" textTransform="camelcase" fontSize={{ xs: "16px", sm: "20px" }} >
                            Buy Now and  <span style={{ color: "#FCED3D", fontFamily: "PM" }}>Join Us</span></Typography>
                        <Typography style={{ textShadow: "0px 0px 11px black", }} data-aos="fade-up" data-aos-duration="900" data-aos-easing="ease-in-out" data-aos-delay="100" data-aos-offset="0" padding="10px 0 0px" variant='h3' fontFamily="PSM" textAlign='left' color="#fff" textTransform="camelcase" fontSize={{ xs: "42px", sm: "70px" }} >
                            How To Buy $Ticker</Typography>

                        <Typography data-aos="fade-up" data-aos-duration="900" data-aos-easing="ease-in-out" data-aos-delay="100" data-aos-offset="0" padding="20px 0 0px" fontFamily="pr" textAlign='left' color="#fff" textTransform="camelcase"
                            fontSize={{ xs: "16px", sm: "20px" }} >
                            <span style={{ color: "#67BA43", fontFamily: "PM" }}>▶ </span>Create a Ethereum (ERC20) wallet (we recommend Metamask or Trust Wallet) and send ETH to your ERC20 wallet address.
                        </Typography>
                        <Typography data-aos="fade-up" data-aos-duration="900" data-aos-easing="ease-in-out" data-aos-delay="100" data-aos-offset="0" padding="20px 0 0px" fontFamily="pr" textAlign='left' color="#fff" textTransform="camelcase"
                            fontSize={{ xs: "16px", sm: "20px" }} >
                            <span style={{ color: "#67BA43", fontFamily: "PM" }}>▶ </span>Now you can easily swap your ETH for $TICKER with Slippage %. Please remember to leave a small amount of ETH for network fees.

                        </Typography>
                        <Typography data-aos="fade-up" data-aos-duration="900" data-aos-easing="ease-in-out" data-aos-delay="100" data-aos-offset="0" padding="20px 0 40px" fontFamily="pr" textAlign='left' color="#fff" textTransform="camelcase"
                            fontSize={{ xs: "16px", sm: "20px" }} >
                            <span style={{ color: "#67BA43", fontFamily: "PM" }}>▶ </span>Congratulations, you are now part of the $Ticker Family!

                        </Typography>

                        <Box style={{ boxShadow: "0px 6px 0px 0px #600303, inset 3px 0px 0px 0px #FDD989"
                            , background: "linear-gradient(0deg, #FA920B ,#FCEE21)" }} data-aos="zoom-in-up" data-aos-duration="900" data-aos-easing="ease-in-out" data-aos-delay="1000" data-aos-offset="0" border='2px solid #C6380C' width="100%" padding="18px 10px" display="flex" alignItems={{ xs: "center", md: "center" }} flexDirection={{ xs: "row", md: "row" }} justifyContent="space-between">
                            <Paragraph color="#5D0608" fontFamily="PSM" fontSize={{ xs: "20px", sm: "20px" }}>
                                CA
                            </Paragraph>
                            <Paragraph color="#5D0608" fontFamily="PR" fontSize={{ xs: "20px", sm: "20px" }} sx={{ overflowWrap: 'anywhere', display: "flex", justifyContent: "center", alignItems: "center", gap: "10px" }}>
                                {
                                    isCopied ? "Copied" : contractAddress
                                }<FileCopyIcon onClick={handleCopy} sx={{ color: "#5D0608", fontSize: "32px", cursor: "pointer" }} />
                            </Paragraph>
                        </Box>
                    </Grid>
                </Grid>


            </Container>
        </SectionWrapper>
    );
};

export default CarouselComponent;
