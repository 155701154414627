import React from "react";
import logo from "../../../../assets/logo.png";
import { DesktopMainMenu, MenuLink } from "../styles";

const DesktopMenu = (props) => {
  return (
    <DesktopMainMenu maxWidth="xl">
      <a href="/">
        <img src={logo} width="80" height="80"  alt="Logo" />
      </a>
      <div>
        {props.menuList.map((value, i) => <MenuLink key={i} href={value.link} target={value.target} className={value.customClass}>{value.title}</MenuLink> )}
      </div>
    
    </DesktopMainMenu>
    
  );
};

export default DesktopMenu;
