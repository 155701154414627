import emotionStyled from '@emotion/styled'
import React, { useEffect, useState } from 'react'
import heroBg from "../../../assets/heroBg.png"
import { Box, Container, Grid, Typography } from '@mui/material'
import { contractAddress } from '../../../links'
import LockerMenu from '../../Components/LockerMenu'
import styled from '@emotion/styled'
import headinging from "../../../assets/heading.png"
import AOS from 'aos';
import 'aos/dist/aos.css'; // Import the AOS styles

import FileCopyIcon from '@mui/icons-material/FileCopy';
import Paragraph from '../../Components/Paragraph'
// Initialize AOS
AOS.init({
    duration: 1200, // Duration of animations (optional)
    once: true, // Whether animation should happen only once - while scrolling down (optional)
    mirror: false, // Whether elements should animate out while scrolling past them (optional)
});

const SectionWraper = emotionStyled(Box)`
    background-image:url(${heroBg});
    background-size:cover;
    background-position: center center;
    background-repeat:no-repeat;
    min-height:110vh;
    text-align:center;
    display:flex;
    justify-content:center;
    position:relative;
    overflow:hidden;
  
`
const ImgHeading = styled('img')`
width: 100%;
height:auto;
padding:20px 0;
max-width:450px;
@media screen and (max-width:599px){
    max-width:300px;

}
`
const Grids = emotionStyled(Grid)`
    display: flex;
    flex-flow: column;
    align-items: center;
    `
const Typographys = emotionStyled(Typography)`
    font-family: 'SM'; 
    margin: 10px 0 30px; /* Reduce top margin to move text upwards */
    font-size: 140px;
    line-height: 99px;
    position: relative;
    color: transparent;
    background: linear-gradient(to top, #FA920B, #FA920B, #FCEE21);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-stroke: 2px #C6380C;
  
    &::before {
      content: attr(data-text);
      position: absolute;
      top: 1px;
      left: 0;
      z-index: -1;
      color: transparent;
      -webkit-text-stroke: none;
      text-shadow: 3px 6px 0px #600303; 
    }
      @media screen and (max-width:499px){
    font-size: 100px;
    line-height: 70px;
      }
      @media screen and (max-width:399px){
    font-size: 85px;
    line-height: 60px;
      }
  `;

const Spans = emotionStyled.span`
    font-family: 'SM'; 
    margin: 0;
    font-size: 140px;
    line-height: 99px;
    position: relative;
    color: transparent;
    background: linear-gradient(to top, #36982E, #36982E, #6DBE45);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-stroke: 2px #034928;
  
    &::before {
      content: attr(data-text);
      position: absolute;
      top: 22px;
      left: -1px;
      z-index: -1;
      color: transparent;
      -webkit-text-stroke: none;
      text-shadow: 5px 8px 0px #034928; /* Updated shadow */
      @media screen and (max-width:399px){
          top: 12px;
      }
    }
    @media screen and (max-width:499px){
    font-size: 100px;
    line-height: 60px;  
      }
    @media screen and (max-width:399px){
    font-size: 85px;
    line-height: 60px;  
      }
  `;

const HeroSection = () => {

    useEffect(() => {
        AOS.refresh();
    }, []); // Replace 'yourDependency' with the state or prop that changes

    const [isCopied, setIsCopied] = useState(false)
    const handleCopy = async () => {
        setIsCopied(true)
        await navigator.clipboard.writeText(contractAddress)
        setTimeout(() => {
            setIsCopied(false)
        }, 3000);
    }
    return (

        <SectionWraper data-aos="fade-zoom-in"
            data-aos-offset="0" data-aos-duration="1200" data-aos-easing="ease-in-out" data-aos-delay="300">
            <Container maxWidth="xl"  >
                <LockerMenu />

                <Grid container sx={{ display: "flex", minHeight: "calc(80vh - 0px)", alignItems: 'flex-end', justifyContent: "center", }}>
                    <Grids item xs={12} md={6} >
                        <Typographys data-text="SANTA DOGE">
                            SANTA  <br />
                            <Spans data-text="DOGE">DOGE</Spans>
                        </Typographys>

                        <Box style={{
                            boxShadow: "0px 6px 0px 0px #600303, inset 3px 0px 0px 0px #FDD989"
                            , background: "linear-gradient(0deg, #FA920B ,#FCEE21)"
                        }} data-aos="zoom-in-up" data-aos-duration="900" data-aos-easing="ease-in-out" data-aos-delay="1000" data-aos-offset="0" border='2px solid #C6380C' width="100%" padding="18px 10px" display="flex" alignItems={{ xs: "center", md: "center" }} flexDirection={{ xs: "row", md: "row" }} justifyContent="space-between">
                            <Paragraph color="#5D0608" fontFamily="PSM" fontSize={{ xs: "20px", sm: "20px" }}>
                                CA
                            </Paragraph>
                            <Paragraph color="#5D0608" fontFamily="PR" fontSize={{ xs: "20px", sm: "20px" }} sx={{ overflowWrap: 'anywhere', display: "flex", justifyContent: "center", alignItems: "center", gap: "10px" }}>
                                {
                                    isCopied ? "Copied" : contractAddress
                                }<FileCopyIcon onClick={handleCopy} sx={{ color: "#5D0608", fontSize: "32px", cursor: "pointer" }} />
                            </Paragraph>
                        </Box>
                    </Grids>
                </Grid>
            </Container>
        </SectionWraper>
    )
}

export default HeroSection