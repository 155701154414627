import { Box, Container, Grid, Typography } from "@mui/material";
import React from "react";
import emotionStyled from "@emotion/styled";

import ballon from "../../../assets/ballon.png"
import AOS from 'aos';
import 'aos/dist/aos.css'; // Import the AOS styles
import styled from "@emotion/styled";
import { supply, tax } from "../../../links";
// Initialize AOS
AOS.init({
    duration: 1200, // Duration of animations (optional)
    once: true, // Whether animation should happen only once - while scrolling down (optional)
    mirror: false, // Whether elements should animate out while scrolling past them (optional)
});
const SectionWrapper = emotionStyled(Box)`
    // background-size: cover;
    // background-repeat: no-repeat;
    // background-position-x: center;
    padding: 180px 0 150PX;
`
const CustomGrid = emotionStyled(Grid)`
  display: flex;
  width: 100%;
  align-items: ${(props) => (props.ai ? props.ai : "center")};
  justify-content: ${(props) => (props.jc ? props.jc : "center")};
  text-align: ${(props) => (props.tl ? props.tl : "center")};
  padding: ${(props) => (props.p ? props.p : "0px")};
  margin: ${(props) => (props.m ? props.m : "0")};
  gap: ${(props) => (props.g ? props.g : "0")};
  
`;

const EcoImg = emotionStyled.img`
width:100%;
height:auto;
`;
const PoolContainer = emotionStyled.div`
background-color:#C8EE5B;
padding:0px 0 10px; 
border-radius: 14px; /* For rounded corners if needed */
border-bottom:2px solid black;
border-left:1px solid black;
border-right:1px solid black;

`;
const Wrapper = styled(Box)`
background: linear-gradient(0deg, #36982E, #6DBE45);
color:#fff;
box-shadow: 2px 6px 0px 0px #034928,inset 2px 0 0 0 #AFDA9F;
border:2px solid #034928;
display:flex;
align-items:center;
justify-content:space-between;
padding:20px 20PX;
margin:20px 0PX;
`
const HowToBuy = () => {
    return (
        <SectionWrapper id="Tokenomics">
            <Container maxWidth="xxl" id="Howtobuy">
                <Container maxWidth="xl" >
                    {/* <CustomGrid data-aos="zoom-in" item xs={12}>
                        </CustomGrid> */}


                    <Grid container spacing={5}>
                        <Grid item xs={12} md={6}>
                            <Typography style={{ textShadow: "0px 0px 11px black", }} data-aos="fade-up" data-aos-duration="900" data-aos-easing="ease-in-out" data-aos-delay="100" data-aos-offset="0" padding={{ xs: "20px 0", sm: "20px 0 80px" }} variant='h3' fontFamily="PSM" textAlign='left' color="#fff" textTransform="camelcase"fontSize={{ xs: "42px", sm: "70px" }} >
                                Tokenomics</Typography>
                            <Wrapper
                                data-aos="fade-up" data-aos-duration="900" data-aos-easing="ease-in-out" data-aos-delay="600" data-aos-offset="0" >
                                <Typography padding="0px 0 " variant='h3' fontFamily="PSM" textAlign='left' color="#fff" textTransform="camelcase"
                                    fontSize={{ xs: "22px", sm: "27px" }} >
                                    Name</Typography>
                                <Typography padding="0px 0 " variant='h3' fontFamily="pr" textAlign='left' color="#fff" textTransform="camelcase"
                                    fontSize={{ xs: "22px", sm: "27px" }} >
                                    Santa Doge </Typography>
                            </Wrapper>
                            <Wrapper
                                data-aos="fade-up" data-aos-duration="900" data-aos-easing="ease-in-out" data-aos-delay="700" data-aos-offset="0" >
                                <Typography padding="0px 0 " variant='h3' fontFamily="PSM" textAlign='left' color="#fff" textTransform="camelcase"
                                    fontSize={{ xs: "22px", sm: "27px" }} >
                                    Symbol</Typography>
                                <Typography padding="0px 0 " variant='h3' fontFamily="pr" textAlign='left' color="#fff" textTransform="camelcase"
                                    fontSize={{ xs: "22px", sm: "27px" }} >
                                    TBA</Typography>
                            </Wrapper>
                            <Wrapper
                                data-aos="fade-up" data-aos-duration="900" data-aos-easing="ease-in-out" data-aos-delay="800" data-aos-offset="0" >
                                <Typography padding="0px 0 " variant='h3' fontFamily="PSM" textAlign='left' color="#fff" textTransform="camelcase"
                                    fontSize={{ xs: "22px", sm: "27px" }} >
                                    Supply</Typography>
                                <Typography padding="0px 0 " variant='h3' fontFamily="pr" textAlign='left' color="#fff" textTransform="camelcase"
                                    fontSize={{ xs: "22px", sm: "27px" }} >
                                    {supply}</Typography>
                            </Wrapper>
                            <Wrapper
                                data-aos="fade-up" data-aos-duration="900" data-aos-easing="ease-in-out" data-aos-delay="900" data-aos-offset="0" >
                                <Typography padding="0px 0 " variant='h3' fontFamily="PSM" textAlign='left' color="#fff" textTransform="camelcase"
                                    fontSize={{ xs: "22px", sm: "27px" }} >
                                    Tax</Typography>
                                <Typography padding="0px 0 " variant='h3' fontFamily="pr" textAlign='left' color="#fff" textTransform="camelcase"
                                    fontSize={{ xs: "22px", sm: "27px" }} >
                                    {tax}</Typography>
                            </Wrapper>
                            <Wrapper
                                data-aos="fade-up" data-aos-duration="900" data-aos-easing="ease-in-out" data-aos-delay="1000" data-aos-offset="0" >
                                <Typography padding="0px 0 " variant='h3' fontFamily="PSM" textAlign='left' color="#fff" textTransform="camelcase"
                                    fontSize={{ xs: "22px", sm: "27px" }} >
                                    Liquidity</Typography>
                                <Typography padding="0px 0 " variant='h3' fontFamily="pr" textAlign='left' color="#fff" textTransform="camelcase"
                                    fontSize={{ xs: "22px", sm: "27px" }} >
                                    Locked</Typography>
                            </Wrapper>
                            <Typography data-aos="fade-up" data-aos-duration="900" data-aos-easing="ease-in-out" data-aos-delay="1000" data-aos-offset="0"  padding="0px 0 " variant='h3' fontFamily="pr" textAlign='left' color="#fff" textTransform="camelcase"
                                fontSize={{ xs: "18px", sm: "22px" }} >
                                10% of the tax will go towards a company which provides christmas presents for children!</Typography>
                        </Grid>

                        <Grid item xs={12} md={6} data-aos="fade-left" data-aos-duration="3000" data-aos-easing="ease-in-out" data-aos-delay="600" data-aos-offset="0">
                            <EcoImg draggable="false" src={ballon} />
                        </Grid>
                    </Grid>

                </Container>
            </Container>
        </SectionWrapper>
    );
};

export default HowToBuy;