import emotionStyled from '@emotion/styled'
import React from 'react'
import Candle from "../../../assets/candle.png"
import Snowclown from "../../../assets/snowclown.png"
import Glove from "../../../assets/glove.png"
import Tag from "../../../assets/tag.png"
import { Box, Container, Grid, Typography } from '@mui/material'
import styled from '@emotion/styled/macro'
import AOS from 'aos';
import 'aos/dist/aos.css'; // Import the AOS styles
import Paragraph from '../../Components/Paragraph'
// Initialize AOS
AOS.init({
    duration: 1200, // Duration of animations (optional)
    once: true, // Whether animation should happen only once - while scrolling down (optional)
    mirror: false, // Whether elements should animate out while scrolling past them (optional)
});

const SectionWraper = emotionStyled(Box)`
    
    display:flex;
    padding:140px 0px;
    justify-content:center;
    align-items:center;
    position:relative;
    @media screen and (max-width:799px){
        padding:80px 0px 0;
    
    }
`
const AboutImage = styled.img`
width: 100%;
height:auto;
max-width: 60px;

`
const AboutSection = () => {


    return (
        <SectionWraper id="Features">
            <Container maxWidth="xl" >
                <Grid container gap="20px">
                    <Grid item xs={12} md={5.2}>
                        <Typography data-aos="fade-up" data-aos-duration="900" data-aos-easing="ease-in-out" data-aos-delay="100" data-aos-offset="0" padding="20px 0 0px" variant='h3' fontFamily="PM" textAlign='left' color="#fff" textTransform="camelcase" fontSize={{ xs: "16px", sm: "20px" }} >
                            Main feature <span style={{ color: "#FCED3D", fontFamily: "PM" }}>points</span></Typography>
                        <Typography style={{ textShadow: "0px 0px 11px black", }} data-aos="fade-up" data-aos-duration="900" data-aos-easing="ease-in-out" data-aos-delay="100" data-aos-offset="0" padding="20px 0 40px" variant='h3' fontFamily="PSM" textAlign='left' color="#fff" textTransform="camelcase" fontSize={{ xs: "42px", sm: "70px" }} >
                            Community Driven
                            & ETH Based Ecosystem</Typography>
                        <Paragraph textAlign="left" data-aos="fade-zoom-in"
     data-aos-easing="ease-in-back"
     data-aos-delay="500"
     data-aos-offset="0" data-aos-duration="900">We are here to spread holiday joy with a fun, crypto twist! Wearing a Santa hat and full of cheer, he’s teaming up with Elon Musk this Christmas for a festive celebration. Together, they’re bringing smiles, memes, and holiday magic to everyone. Much wow, very merry! Our beloving name will be revealed after our launch on Ethereum!
                        </Paragraph>
                    </Grid>
                    <Grid xs={0.1} />
                    <Grid item xs={12} md={6}>
                        <Grid container sx={{ display: "flex", justifyContent: "center", alignItems: { xs: "start", md: "center" } }}>
                            {/* sx={{ display: "flex", justifyContent: "center", alignItems: "center", padding: "0 0px 0px", }} */}
                            <Grid item xs={12} md={6}
                                data-aos="zoom-in-left" data-aos-duration="900" data-aos-easing="ease-in-out" data-aos-delay="100" data-aos-offset="0" >
                                <AboutImage style={{ maxWidth: "40px" }} alt="image" draggable="false" src={Candle} data-aos="fade-up" data-aos-duration="1000" data-aos-easing="ease-in-out" data-aos-delay="300" data-aos-offset="0" />
                                <Typography padding="5px 0 0px" variant='h3' fontFamily="PSM" textAlign='left' color="#fff" textTransform="camelcase" fontSize={{ xs: "22px", sm: "25px" }} >
                                    Liquidity Locked</Typography>
                                <Typography padding="20px 0 40px" variant='h3' fontFamily="PR" textAlign='left' color="#fff" textTransform="camelcase" fontSize={{ xs: "16px", sm: "18px" }} >
                                    Liquidity Locked</Typography>

                            </Grid>
                            <Grid item xs={12} md={6}
                                data-aos="zoom-in-right" data-aos-duration="900" data-aos-easing="ease-in-out" data-aos-delay="100" data-aos-offset="0" >
                                <AboutImage alt="image" draggable="false" src={Snowclown} data-aos="fade-up" data-aos-duration="1000" data-aos-easing="ease-in-out" data-aos-delay="300" data-aos-offset="0" />
                                <Typography padding="5px 0 0px" variant='h3' fontFamily="PSM" textAlign='left' color="#fff" textTransform="camelcase" fontSize={{ xs: "22px", sm: "25px" }} >
                                    Security</Typography>
                                <Typography padding="20px 0 40px" variant='h3' fontFamily="PR" textAlign='left' color="#fff" textTransform="camelcase" fontSize={{ xs: "16px", sm: "18px" }} >
                                    Contract renounced</Typography>

                            </Grid>
                            <Grid item xs={12} md={6}
                                data-aos="zoom-in-left" data-aos-duration="900" data-aos-easing="ease-in-out" data-aos-delay="100" data-aos-offset="0" >
                                <AboutImage alt="image" draggable="false" src={Glove} data-aos="fade-up" data-aos-duration="1000" data-aos-easing="ease-in-out" data-aos-delay="300" data-aos-offset="0" />
                                <Typography padding="5px 0 0px" variant='h3' fontFamily="PSM" textAlign='left' color="#fff" textTransform="camelcase" fontSize={{ xs: "22px", sm: "25px" }} >
                                    Community Driven</Typography>
                                <Typography padding="20px 0 40px" variant='h3' fontFamily="PR" textAlign='left' color="#fff" textTransform="camelcase" fontSize={{ xs: "16px", sm: "18px" }} >
                                    Fully decentralized and<br />
                                    community driven</Typography>

                            </Grid>
                            <Grid item xs={12} md={6}
                                data-aos="zoom-in-right" data-aos-duration="900" data-aos-easing="ease-in-out" data-aos-delay="100" data-aos-offset="0" >
                                <AboutImage style={{ maxWidth: "80px" }} alt="image" draggable="false" src={Tag} data-aos="fade-up" data-aos-duration="1000" data-aos-easing="ease-in-out" data-aos-delay="300" data-aos-offset="0" />
                                <Typography padding="5px 0 0px" variant='h3' fontFamily="PSM" textAlign='left' color="#fff" textTransform="camelcase" fontSize={{ xs: "22px", sm: "25px" }} >
                                    Charity</Typography>
                                <Typography padding="20px 0 40px" variant='h3' fontFamily="PR" textAlign='left' color="#fff" textTransform="camelcase" fontSize={{ xs: "16px", sm: "18px" }} >
                                    Christmas presents for <br />
                                    children!</Typography>

                            </Grid>

                        </Grid>
                    </Grid>
                </Grid>

            </Container>
        </SectionWraper >
    )
}

export default AboutSection