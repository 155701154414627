import emotionStyled from '@emotion/styled'
import React from 'react'
import { Box, Container, Grid } from '@mui/material'
import Paragraph from '../../Components/Paragraph'
import styled from '@emotion/styled'

const SectionWraper = emotionStyled(Box)`
padding:0PX 0 20px;
border-top:1px solid #fff;
    width:100%;
    display:flex;
    justify-content:center;
    align-items:center;
    position:relative;
`

const DisclaimerSection = () => {


    return (
        <>
                <Container maxWidth="xl">
            <SectionWraper>

                    <Paragraph padding="20px 0 0" color="#fff" textAlign="center" fontFamily="PR" >
                        © 2024 $Ticker Project All Right Reserved                            </Paragraph>
            </SectionWraper>
                </Container>

        </>
    )
}

export default DisclaimerSection